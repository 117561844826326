.hero {
  position: relative;
  display: flex;
  align-items: center;
  height: calc(100vh - 103px);
}

.hero__bg {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: -105px;
  background: radial-gradient(130.32% 65.12% at 43.78% 52.94%, rgba(199, 180, 250, 0.35) 0%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(66.62% 46.68% at 34.27% 29.6%, rgba(198, 175, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(41.99% 67.32% at 13.8% 42.46%, rgba(203, 255, 190, 0.64) 0%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(123.37% 46.03% at 114.61% 113.36%, rgba(187, 249, 172, 0.80) 0%, rgba(255, 255, 255, 0.00) 100%), rgba(255, 255, 255, 0.50);
}

.hero__title {
  position: relative;
  font-size: 70px;
  font-weight: 700;
  padding: 0 16px;
  line-height: 110%;
  margin-bottom: 27px;
  max-width: 1071px;
}

.highlights {
  position: relative;
  color: var(--white);
  white-space: nowrap;

  span {
    position: relative;
    z-index: 11;
  }

  &:after {
    z-index: 1;
    position: absolute;
    content: '';
    border-radius: 21px;
    top: 19px;
    height: 75%;
    width: 103%;
    background: var(--main-black);
    left: -6px;
  }
}

.header__desc-wp {
  padding-left: 180px;
}

.header__desc {
  position: relative;
  font-size: 24px;
  font-weight: 400;
  line-height: 165%;
  letter-spacing: 0.24px;
  margin-bottom: 32px;
  max-width: 757px;
}

@media screen and (max-width: 992px) {
  .header__desc-wp {
    padding-left: 0;
  }

  .hero__title {
    padding-left: 0;
  }
}

@media screen and (max-width: 555px) {
  .hero__title {
    padding-right: 0;
    font-size: 40px;
    margin-bottom: 33px;
  }

  .highlights:after {
    border-radius: 14px;
    top: 10px;
    height: 75%;
    width: 106%;
    left: -7px;
  }

  .header__desc {
    font-size: 18px;
  }

  .header__desc-wp .button {
    justify-content: center;
    width: 100%;
  }
}