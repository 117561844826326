.footer-flex {
  padding-top: 120px;
  padding-bottom: 44px;
  display: flex;
  gap: 16px;
  justify-content: space-between;

  > div {
    width: 50%;
  }
}

.footer-logo {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 24px;

  span {
    color: var(--aztec-purple);
  }
}

.footer-desc {
  color: rgba(31, 32, 35, 0.55);
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 24px;
  max-width: 444px;
}

.footer-info-title {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  margin-bottom: 40px;
}

.footer-link {
  display: flex;
  gap: 44px;
  color: rgba(31, 32, 35, 0.55);
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  a {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      height: 1px;
      background-color: var(--aztec-purple);
      width: 16px;
      left: -22px;
      top: 50%;
      opacity: 0;
    }

    &:hover {
      color: var(--aztec-purple);

      &:after {
        opacity: 1;
      }
    }
  }
}

.copyright {
  border-top: 1px solid var(--mercury);
  width: 100%;
  padding-top: 32px;
  padding-bottom: 44px;
  color: rgba(31, 32, 35, 0.45);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}

@media screen and (max-width: 991px) {
  .footer-flex {
    flex-direction: column;
    gap: 44px;
  }

  .footer-link a {
    white-space: nowrap;

    &:hover {

      &:after {
        opacity: 0;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .footer-flex {
    padding-top: 88px;
  }

  .footer-flex > div {
    width: 100%;
  }

  .footer-link {
    flex-direction: column;
    gap: 36px;
  }
}