.container {
  max-width: 1472px;
  padding: 0 16px;
  margin: 0 auto;
  width: 100%;
}

.container-width {
  max-width: 100%;
  margin: 0 16px;
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 10;
  color: var(--white);
  padding: 24px 60px;
  border-radius: 100px;
  background-color: var(--aztec-purple);
  font-size: 20px;
  font-weight: 500;
  outline: none;
  border: none;
  transition: background-color 0.2s linear;
  max-height: 72px;

  &:hover {
    background-color: var(--daisy-bush);
  }
}

.iti--separate-dial-code .iti__selected-flag {
  background-color: transparent!important;
}

.iti--separate-dial-code.iti--show-flags .iti__selected-dial-code {
  color: var(--main-black);
  margin-left: 8px;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 165%;
  letter-spacing: 0.18px;
}

.iti__country-list {
  z-index: 20;
}

.iti__arrow {
  border-style: solid!important;
  content: '';
  display: inline-block;
  left: 0.15em;
  position: relative;
  top: -3px;
  transform: rotate(135deg);
  vertical-align: top;
  border-color: #061C3D!important;
  border-width: 2px 2px 0 0 !important;
  height: 12px;
  width: 12px;
  border-left: none;
  border-right: none;
  border-top: none;

  border-left: none;
  border-right: none;
  border-top: none;
}

.iti input, .iti input[type=text], .iti input[type=tel] {
  padding-left: 128px!important;
}

.hidden {
  display: none!important;
}

.opacity {
  opacity: 0!important;
  pointer-events: none;
}