.recruit-wp {
  padding: 120px 0;
}

.recruit-title {
  margin-bottom: 60px;
  font-size: 50px;
  font-weight: 600;
  line-height: 120%;
  max-width: 704px;
}

.recruit-mt {
  margin-top: 33px;
}

.recruit-flex {
  display: flex;
  gap: 33px;
  justify-content: center;
}

.recruit-item {
  position: relative;
  padding: 24px;
  background: linear-gradient(0deg, #ECFFE8 0%, #ECFFE8 100%), #FFF;
  border-radius: 32px;
  flex-basis: 418px;
}

.abstract-line {
  position: absolute;
  right: -39px;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
}

.item-number {
  height: 100%;
  position: absolute;
  bottom: 39px;
  right: 24px;
  font-size: 180px;
  color: rgba(91, 211, 125, 0.15);
}

.recruit-item__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--paris-green);
  margin-bottom: 12px;
}

.recruit-item__title {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 165%;
  letter-spacing: 0.18px;
  margin-bottom: 4px;
}

.recruit-item__desc {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 175%;
  letter-spacing: 0.16px;
}

@media screen and (max-width: 992px) {
  .recruit-flex {
    flex-direction: column;
    gap: 24px;
  }

  .recruit-mt {
    margin-top: 24px;
  }

  .recruit-item {
    flex-basis: initial;
  }

  .abstract-line {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .recruit-wp {
    padding: 60px 0;
  }

  .recruit-title {
    font-size: 32px;
  }
}

@media screen and (max-width: 400px) {
  .recruit-flex {
    margin: 0 -16px;
  }
}