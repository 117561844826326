.best {
  background-size: cover;
  background: #F6F6F6 url("../static/background/bg-hero.webp") no-repeat;
  background-size: cover;
  border-radius: 60px;
}

.best-wp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 120px 0;
}

.best-title {
  text-align: center;
  font-size: 50px;
  font-weight: 600;
  line-height: 120%;
  max-width: 778px;
  margin-bottom: 44px;
}

.best-button {
  display: flex;
  justify-content: center;
  width: 100%;
}

.scroll-hidden {
  overflow: hidden!important;
}

@media screen and (max-width: 575px) {
  .best-wp {
    padding: 240px 0;
  }

  .best-title {
    font-size: 35px;
  }

  .container-width {
    margin: 0;
  }
}

@media screen and (max-width: 400px) {
  .best-wp {
    padding: 180px 0;
  }
}