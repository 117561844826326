.grid-scroll {
  overflow: hidden;
  max-width: 1520px;
}

.developers-grid {
  grid-gap: 24px;
  margin-top: 66px;
  margin-bottom: 85px;
  overflow: hidden;
}

.slider-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 60px;
  border-radius: 100px;
  border: 1px solid var(--main-black);
  cursor: pointer;
}

.groupBtnSlider {
  display: flex;
  justify-content: center;
  gap: 32px;
  margin-top: 45px;
}

.developers-header {
  display: flex;
  align-items: center;
  gap: 16px;
}

.developers-item {
  flex: 0 0 100%;
  min-width: 0;
  border-radius: 18px;
  border: 1px solid var(--mercury);
  box-shadow: 0px 80px 100px 0px rgba(32, 45, 27, 0.04);
  padding: 24px;
  max-width: 360px;
  width: 100%;
}

.flexListGrid {
  display: flex;
  gap: 16px;
}

.developer-info {
  width: 100%;
}

.developers-item__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.18px;
  margin-bottom: 4px;
}

.developers-item__company {
  font-family: 'Inter', sans-serif;
  font-size: 10px;
}

.developers-item__age {
  font-family: 'Inter', sans-serif;
  color: rgba(30, 32, 33, 0.55);
  font-size: 10px;
  letter-spacing: 0.1px;
}

.developers-price {
  font-family: 'Inter', sans-serif;
  color: var(--aztec-purple);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.18px;
}

.developers-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 16px 0;

  > div {
    font-family: 'Inter', sans-serif;
    padding: 4px 8px;
    background-color: var(--rice-flower);
    border-radius: 18px;
    color: var(--apple);
    font-weight: 400;
    font-size: 10px;
  }
}

.developers-desc {
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 165%;
  letter-spacing: 0.1px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.developers-grid::-webkit-scrollbar {
  width: 0;
}

.developers-grid::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.developers-grid::-webkit-scrollbar-track {
  background-color: transparent;
}

@media screen and (max-width: 575px) {
  .developers-grid {
    margin-bottom: 0;
  }
}