.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 24px;

  &__logo {
    display: flex;
    font-size: 30px;
    font-weight: 700;
    position: relative;
    z-index: 1;

    p {
      color: var(--dark-jungle-green);
    }

    span {
      color: var(--aztec-purple);
    }
  }
}

.header__contact {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  color: var(--white);
  font-size: 18px;
  font-weight: 500;
  gap: 10px;
  padding: 14px 32px;
  border-radius: 100px;
  background-color: var(--main-black);
  border: 1px solid var(--main-black);
  transition: background-color 0.2s linear;

  &:hover {
    background-color: var(--aztec-purple);
  }

  &:active {
    background-color: var(--daisy-bush);
  }
}

.header__nav-link {
  display: flex;
  position: relative;
  z-index: 1;
  gap: 56px;
  font-size: 18px;
  font-weight: 500;
}

.menu-mobile {
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px 16px;
  background-color: var(--white);
  height: calc(100vh - 103px);
  width: 100vw;
  left: 0;
  top: 103px;
  z-index: 100;
  opacity: 0;
  pointer-events: none;
}

.list-link-mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 100%;
}

.link-mobile {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.18px;
  text-align: center;
  padding: 24px 60px;
  width: 100%;
  border-radius: 16px;
  border: 1px solid var(--gainsboro-light);
  margin-bottom: 10px;
}

.contact-up-mobile {
  padding: 24px 32px;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 44px;
  font-size: 18px;
  width: 100%;
}

.mobile {
  display: none;
}

.header__contact.mobile:hover {
  background-color: var(--main-black);
}

.header__contact.active {
  background-color: var(--white) !important;
  color: var(--main-black);
}

.header.active {
  position: relative;
  z-index: 111;
  background-color: var(--white);
}

@media screen and (max-width: 992px) {
  .header__nav-link {
    display: none;
  }

  .menu-mobile {
    opacity: 1;
    pointer-events: initial;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: flex;
  }
}
