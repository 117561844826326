.contacts {
  position: relative;
  margin-top: 120px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #F6F6F6 100%);
}

.contacts-flex {
  display: flex;
  gap: 64px;
  overflow: hidden;
  position: relative;

  > div {
    width: 50%;
  }
}

.contacts-form {
  position: relative;
  width: 50%;

  > button {
    margin-top: 44px;
  }
}

.contacts-info {
  position: relative;
  padding-bottom: 294px;
}

.circle {
  height: 358px;
  width: 358px;
  position: absolute;
  background: url("../static/abstrackt/circle.svg") no-repeat;
  bottom: -85px;
}

.circle-mobile {
  display: none;
  height: 358px;
  width: 358px;
  position: absolute;
  background: url("../static/abstrackt/circle.svg") no-repeat;
  bottom: -160px;
  transform: translateX(-50%);
  left: 50%;
}

.contacts-title {
  color: var(--aztec-purple);
  font-size: 60px;
  font-weight: 600;
  line-height: 110%;
  margin-bottom: 24px;
  max-width: 660px;
}

.contacts-desc {
  font-family: 'Inter', sans-serif;
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 165%;
  letter-spacing: 0.18px;
}

.contacts-bold {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 165%;
  letter-spacing: 0.2px;
}

.contacts-field {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px 24px;
  border-radius: 24px;
  border: 1px solid var(--gainsboro);
  background: var(--sugar-cane);
  margin-bottom: 24px;
}

.mb0 {
  margin-bottom: 0;
}

.contacts-label {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 600px;
  color: rgba(31, 32, 35, 0.50);

  input {
    background: transparent;
    border: none;
    outline: none;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    line-height: 165%;
    letter-spacing: 0.18px;
    padding: 0;
  }
}

@media screen and (max-width: 830px) {
  .contacts-flex {
    flex-direction: column;
    gap: 40px;

    > div {
      width: 100%;
    }
  }

  .contacts-info {
    padding-bottom: 0;
  }

  .contacts-form {
    padding-bottom: 120px;
    width: 100%;
  }

  .circle {
    display: none;
  }

  .circle-mobile {
    display: initial;
  }
}

@media screen and (max-width: 575px) {
  .contacts {
    margin-top: 140px;
  }

  .contacts-title {
    font-size: 35px
  }

  .contacts-form > button {
    justify-content: center;
    width: 100%;
  }
}