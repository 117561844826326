.process {
  background-color: var(--aztec-purple);
  border-radius: 60px;
}

.process-wp {
  padding: 120px 60px;
  margin-top: 24px;
}

.process-title {
  text-align: center;
  font-size: 50px;
  font-weight: 600;
  color: var(--white);
  line-height: 110%;
  margin-bottom: 24px;
}

.process-desc {
  font-family: 'Inter', sans-serif;
  max-width: 550px;
  color: var(--white);
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 165%;
  letter-spacing: 0.18px;
  margin: 0 auto;
}

.process-steps {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 48px;
  margin-top: 64px;
}

.process-item-header {
  display: flex;
  align-items: center;
  gap: 60px;
  margin-bottom: 16px;
}

.process-item-header__number {
  font-family: 'Inter', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 60px;
  min-width: 60px;
  width: 100%;
  height: 60px;
  color: var(--aztec-purple);
  border-radius: 50%;
  background-color: var(--very-pale-green);
  font-size: 38px;
  line-height: 165%;
}

.process-item-header__title {
  font-family: 'Inter', sans-serif;
  color: var(--white);
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 600;
  line-height: 165%;
  letter-spacing: 0.18px;
}

.process-line {
  height: 1px;
  background-image: linear-gradient(to right, var(--aztec-purple) 36%, #ffffff80 10%);
  background-size: 10px 1px;
  background-repeat: repeat-x;
  width: 100%;
}

.process-item-header__desc {
  font-family: 'Inter', sans-serif;
  color: var(--white);
  font-size: 16px;
  line-height: 175%;
  letter-spacing: 0.16px;
}


@media screen and (max-width: 1180px) {
  .process-steps {
    grid-gap: 12px;
  }
}

@media screen and (max-width: 992px) {
  .process-steps {
    grid-template-columns: initial;
    grid-gap: 48px;
  }

  .process-item-header {
    gap: 24px;
  }
}

@media screen and (max-width: 575px) {
  .process-title {
    font-size: 35px;
  }

  .process-wp {
    padding: 120px 0;
  }
}